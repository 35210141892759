define("discourse/plugins/discourse-education-category-custom-field/discourse/lib/category-custom-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fieldInputTypes = fieldInputTypes;
  function fieldInputTypes(fieldType) {
    return {
      isBoolean: fieldType === 'boolean',
      isString: fieldType === 'string',
      isInteger: fieldType === 'integer',
      isJson: fieldType === 'json'
    };
  }
});